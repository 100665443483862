import { initForms } from './components/initForms';
import { initJivosite } from './components/initJivosite';
import { initHashEmail } from './components/initHashEmail';
import { initScrollLocker } from './tools/scrollLocker';
import { initHashMessage } from './components/initHashMessage';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initAutoresizeTextarea } from './components/initAutoresizeTextarea';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';
import { gtm } from './components/analytics/gtm';
import { fbPixel } from './components/analytics/fb-pixel';
import { tiktokPixel } from './components/analytics/tiktok-pixel';
import { twitter } from './components/analytics/twitter';
import { bingAds } from './components/analytics/bing-ads';
import { lazyLoadAnalytics } from './components/lazyLoadAnalytics';
import { pushengage } from './components/analytics/pushengage';

window.addEventListener('DOMContentLoaded', handleOnLoadPage, false);

function handleOnLoadPage() {
    initAutoresizeTextarea();
    initLazyLoadFonts();
    initScrollLocker();
    initForms();
    initJivosite();
    initHashEmail();
    initHashMessage();
    initMixpannel();
    initEmailAutocompleteDropdown();
    // Analytics
    lazyLoadAnalytics(gtm);
    lazyLoadAnalytics(fbPixel);
    lazyLoadAnalytics(tiktokPixel);
    lazyLoadAnalytics(twitter);
    lazyLoadAnalytics(bingAds);
    lazyLoadAnalytics(pushengage);
}
